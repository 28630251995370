<template lang="pug" functional>
tr.scoring-model-check-param-show.animated.fadeIn
  td {{ props.checkParameter.description }}
  td {{ props.checkParameter.fromValue }}
  td {{ props.checkParameter.toValue }}
  td {{ props.checkParameter.score }}
</template>


<script>
export default {
  name: 'scoring-model-check-param-show',

  props: {
    checkParameter: {
      type: Object,
      required: true
    }
  }
}
</script>
