<template lang="pug">
  tr.scoring-model-check-param-edit.animated.fadeIn
    td
      input.form-control-sm.form-control(
        v-if    = 'editable'
        v-model = 'checkParameter.description'
      )
      template(v-else) {{ checkParameter.description }}
    td
      input.form-control-sm.form-control(
        v-if    = 'editable'
        v-model = 'checkParameter.fromValue'
      )
      template(v-else) {{ checkParameter.fromValue }}
    td
      input.form-control-sm.form-control(
        v-if    = 'editable'
        v-model = 'checkParameter.toValue'
      )
      template(v-else) {{ checkParameter.toValue }}
    td
      input.form-control-sm.form-control(v-model = 'checkParameter.score')
    td
      .btn-group.btn-group-sm(v-if = 'editable' )
        button.btn.btn-primary(@click.prevent = '$emit("add")')
          i.far.fa-plus-square.fa-lg
        button.btn.btn-danger(@click.prevent = '$emit("delete")')
          i.far.fa-trash-alt.fa-lg
</template>


<script>
export default {
  name: 'scoring-model-check-param-edit',

  props: {
    editable: {
      type: Boolean
    },
    checkParameter: {
      type: Object,
      required: true
    }
  }
}
</script>
