<template lang="pug">
.person-scoring-request-form
  .card
    .card-header {{ model.description }}
    .card-body
      form.form-horizontal.col-lg-9.col-xl-6(@submit.prevent = 'onSubmit')
        fi-form-field(:label = '$t("firstName")')
          input.form-control.form-control-sm(
            type         = 'text'
            v-model.trim = 'requestData.firstName'
          )
        fi-form-field(:label = '$t("lastName")')
          input.form-control.form-control-sm(
            type         = 'text'
            v-model.trim = 'requestData.lastName'
          )
        fi-form-field(:label = '$t("idCode")')
          input.form-control.form-control-sm(
            type         = 'text'
            v-model.trim = 'requestData.idCode'
          )
        fi-form-field(:label = '$t("idDocumentType")')
          fi-select(
            v-model.number = 'requestData.idDocId'
            :options       = 'optionsFromClassifier("idDocs")'
            sm
          )
        fi-form-field(:label = '$t("idDocumentNumber")')
          input.form-control.form-control-sm(
            type         = 'text'
            v-model.trim = 'requestData.idDocNumber'
          )
        fi-form-field(:label = '$t("age")')
          input.form-control.form-control-sm(
            type           = 'text'
            v-model.number = 'requestData.age'
          )
        fi-form-field(:label = '$t("county")')
          fi-select(
            v-model.number = 'requestData.countyId'
            :options       = 'optionsFromClassifier("counties")'
            sm
          )
        fi-form-field(:label = '$t("employmentLength")')
          input.form-control.form-control-sm(
            type           = 'text'
            v-model.number = 'requestData.employmentLength'
          )

        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        ) {{ $t('execute') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'person-scoring-request-form',

  components: {
    FiFormField,
    FiSelect
  },

  props: {
    model: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      requestData: {
        firstName: null,
        lastName: null,
        idCode: null,
        idDocId: '',
        idDocNumber: null,
        age: null,
        countyId: '',
        employmentLength: null
      }
    }
  },

  computed: {
    ...mapState('scoring', ['currentRequest']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    saving () {
      return this.$vueLoading.isLoading('scoring:request:save')
    }
  },

  created () {
    this.resetData()
  },

  methods: {
    ...mapActions('scoring', ['createRequest']),
    resetData () {
      if (!this.customer) return
      this.requestData = {
        ...this.requestData,
        ...pick(this.customer, Object.keys(this.requestData))
      }
    },
    async onSubmit () {
      await this.createRequest({ modelId: this.model.id, requestData: this.requestData, type: this.model.modelType.toLowerCase() })
      this.$router.push({ name: 'ScoringRequest', params: { id: this.currentRequest.id, type: this.model.modelType.toLowerCase() } })
    }
  }
}
</script>


<i18n>
en:
  firstName:        "First name"
  lastName:         "Last name"
  idCode:           "ID code"
  idDocumentType:   "ID document type"
  idDocumentNumber: "ID document number"
  age:              "Age"
  county:           "County"
  employmentLength: "Employment length"
  execute:          "Execute"
et:
  firstName:        "Eesnimi"
  lastName:         "Perekonnanimi"
  idCode:           "Isikukood"
  idDocumentType:   "Konto avaja documendi tüüp"
  idDocumentNumber: "Dokumendi number"
  age:              "Vanus"
  county:           "Taotleja elukoha maakond"
  employmentLength: "Töötatud aegu kuus"
  execute:          "Execute"
ru:
  firstName:        "Имя"
  lastName:         "Фамилия"
  idCode:           "ID код"
  idDocumentType:   "Тип документа"
  idDocumentNumber: "Номер документа"
  age:              "Возраст"
  county:           "Округ"
  employmentLength: "Стаж"
  execute:          "Выполнить"
</i18n>
