<template lang="pug">
.card.finance-scoring-request
  .card-header {{ $t('requestTitle', { model: model.description, id: request.id }) }}
  .card-body
    .col-lg-8.col-xl-6
      fi-data-field(:label = '$t("date")') {{ $t('common:formatDatetime', { value: request.createdAt }) }}
      fi-data-field(:label = '$t("scoringModel")') {{ model.modelName }}
      fi-data-field(:label = '$t("idCode")') {{ request.idCode }}
      fi-data-field(:label = '$t("name")') {{ customerName(request) }}
      template(v-if = '!isPreScoringModel')
        fi-data-field(:label = '$t("loanAmount")') {{ request.loanAmount }}
        fi-data-field(:label = '$t("loanLength")') {{ request.loanLength }}
        fi-data-field(:label = '$t("ageAfterLoanPeriod")') {{ request.ageAfterLoanPeriod }}
      fi-data-field(:label = '$t("obligations")') {{ request.obligations }}
      fi-data-field(:label = '$t("netIncome")') {{ request.netIncome }}
      template(v-if = '!isPreScoringModel')
        fi-data-field(:label = '$t("idDocumentType")') {{ classifierById('idDocs', request.idDocId).human }}
        fi-data-field(:label = '$t("idDocumentNumber")') {{ request.idDocNumber }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'

export default {
  name: 'finance-scoring-request',

  components: { FiDataField },

  props: {
    request: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('customers', ['customerName']),
    isPreScoringModel () {
      return this.model.modelName === 'preScoring'
    }
  }
}
</script>


<i18n>
en:
  requestTitle:       "{{model}} request #{{id}}"
  date:               "Date"
  scoringModel:       "Scoring model"
  idCode:             "ID code"
  name:               "Name"
  loanAmount:         "Loan amount"
  loanLength:         "Loan length"
  ageAfterLoanPeriod: "Age after loan period"
  obligations:        "Obligations"
  netIncome:          "Net income"
  idDocumentType:     "ID document type"
  idDocumentNumber:   "ID document number"
et:
  requestTitle:       "{{model}} request #{{id}}"
  date:               "Date"
  scoringModel:       "Otsingumudel"
  idCode:             "Isikukood"
  name:               "Nimi"
  loanAmount:         "Taotletav finantseeringu summa"
  loanLength:         "Perioodi pikkus kuudes"
  ageAfterLoanPeriod: "Taotleja vanus perioodi löpus"
  obligations:        "Taotleja olemasolevad finantskohustused"
  netIncome:          "Taotleja netosissetuleku summa"
  idDocumentType:     "Konto avaja documendi tüüp"
  idDocumentNumber:   "Dokumendi number"
ru:
  requestTitle:       "{{model}} request #{{id}}"
  date:               "Дата"
  scoringModel:       "Scoring model"
  idCode:             "ID код"
  name:               "Имя"
  loanAmount:         "Сумма кредита"
  loanLength:         "Срок кредита"
  ageAfterLoanPeriod: "Возраст по окончании кредита"
  obligations:        "Финансовые обязательства"
  netIncome:          "Доход"
  idDocumentType:     "Тип ID документа"
  idDocumentNumber:   "Номер ID документа"
</i18n>

