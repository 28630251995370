<template lang="pug">
  .scoring-model
    .animated.fadeIn
      .card
        .card-header {{ $t('modelConfigsTitle') }}
        .card-body
          .row
            .col-lg-4
              fi-form-field(
                :label      = '$t("type")'
                :label-cols = '4'
              )
                fi-select(
                  v-model = 'modelData.modelType'
                  :options = 'modelTypes'
                  sm
                )

              fi-form-field(
                :label      = '$t("name")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.modelName')

              fi-form-field(
                :label      = '$t("kyc")'
                :label-cols = '4'
              )
                fi-switch(
                  v-model   = 'modelData.kycCheck'
                  :on-text  = '$t("common:yes")'
                  :off-text = '$t("common:no")'
                )

              fi-form-field(
                :label      = '$t("description")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.description')
          .row
            .col-lg-4
              fi-form-field(
                :label      = '$t("positiveScore")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.positiveScoreValue')

              fi-form-field(
                :label      = '$t("warningScore")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.warningScoreValue')

              fi-form-field(
                :label      = '$t("negativeScore")'
                :label-cols = '4'
              )

            .col-lg-4
              fi-form-field(
                :label      = '$t("scoreDescription")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.positiveScoreHuman')

              fi-form-field(
                :label      = '$t("scoreDescription")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.warningScoreHuman')

              fi-form-field(
                :label      = '$t("scoreDescription")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.negativeScoreHuman')

            .col-lg-4
              fi-form-field(
                :label      = '$t("scoreStopValue")'
                :label-cols = '4'
              )
                input.form-control.form-control-sm(v-model.trim = 'modelData.firstStopValue')

              fi-form-field(
                :label      = '$t("scoreStopValue")'
                :label-cols = '4'
              )
                  input.form-control.form-control-sm(v-model.trim = 'modelData.secondStopValue')
        .card-header {{ $t('scoreChecksTitle') }}
        .card-body
          .row
            .col-lg-4
              fi-form-field(
                :label      = '$t("country")'
                :label-cols = '4'
              )
                fi-select(
                  v-model  = 'country'
                  :options = 'optionsFromClassifier("countries")'
                  sm
                )
            .col-lg
              fi-form-field(
                :label      = '$t("addChecks")'
                :label-cols = '2'
              )
                .row
                  .col-6
                    .card
                      .card-header {{ $t('common:selectBlank') }}
                      draggable.list-group.list-group-flush.check-list(
                        v-model = 'checksForSelect'
                        group  = 'checks'
                        :sort  = 'false'
                      )
                        button.list-group-item-action.list-group-item.justify-content-between.d-flex.align-items-center(
                          v-for = '(check, idx) in checksForSelect'
                          :key  = 'idx'
                          @click.prevent
                        ) {{ check.name }}
                  .col-6
                    .card
                      .card-header {{ $t('selected') }}
                      draggable.list-group.list-group-flush.check-list(
                        v-model = 'modelData.modelChecks'
                        group  = 'checks'
                        @update  = 'sort'
                      )
                        button.list-group-item-action.list-group-item.justify-content-between.d-flex.align-items-center(
                          v-for = '(check) in modelData.modelChecks'
                          :key  = 'check.order'
                          @click.prevent
                        ) {{ check.name }}
          button.btn.btn-primary.mr-sm-2(
            :disabled = 'saving'
            @click    = 'saveModel'
          ) {{ $t('save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
          router-link.btn.btn-secondary(
            :to       = '{ name: "ScoringModelList" }'
            :disabled = 'saving'
          ) {{ $t('common:cancel') }}

        scoring-model-check.animated.fadeIn(
          v-for          = 'check in checksData'
          :key           = 'check.id'
          :check         = 'check'
          :editable.sync = 'editable'
          @save          = 'saveCheckParams'
        )
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import draggable from 'vuedraggable'
import api from '@/api'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import ScoringModelCheck from '@/views/admin/scoringModels/ScoringModelCheck'
import FiSwitch from '@/components/FiSwitch'

const mapChecks = (scoringChecks) => scoringChecks.map(({ id, methodName: name, description, editable }, order) => ({
  id,
  name,
  description,
  editable,
  order
}))

export default {
  name: 'scoring-model-edit',

  components: { FiSwitch, ScoringModelCheck, FiSelect, FiFormField, draggable },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    editable: true,
    checksForSelect: [],
    country: '',
    checksData: null,
    modelData: {
      modelChecks: [],
      description: null,
      kycCheck: false,
      firstStopValue: null,
      modelName: null,
      negativeScoreHuman: null,
      positiveScoreHuman: null,
      positiveScoreValue: null,
      secondStopValue: null,
      modelType: null,
      warningScoreHuman: null,
      warningScoreValue: null
    }
  }),

  computed: {
    ...mapState('scoring', ['model', 'checkParams']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    fields () {
      return [
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'fromValue',
          label: this.$t('fromValue')
        },
        {
          key: 'toValue',
          label: this.$t('toValue')
        },
        {
          key: 'score',
          label: this.$t('score')
        }
      ]
    },
    modelTypes () {
      return ['PRIVATE', 'CORPORATE']
    },
    saving () {
      return this.$vueLoading.isLoading('scoring:model:save')
    }
  },

  watch: {
    async country (countryId) {
      await this.loadScoringCheckList({ countryId, modelType: this.modelData.modelType })
    }
  },

  async created () {
    if (this.id) {
      await this.loadScoringModel({ modelId: this.id })
      this.resetData()
    }
  },

  methods: {
    ...mapActions('scoring', ['loadModelConfigs', 'postScoringModel', 'loadScoringModel', 'updateScoringModel', 'createScoringModel', 'updateScoringModelChecks']),
    resetData () {
      this.modelData = {
        ...this.modelData,
        ...pick(this.model, Object.keys(this.modelData))
      }

      this.modelData.modelChecks = mapChecks(this.model.scoringChecks)
      this.checksData = cloneDeep(this.model.scoringChecks)
    },
    async loadScoringCheckList ({ countryId, modelType }) {
      const { data: checks } = await api.getScoringChecks({ modelType, countryId })
      this.checksForSelect = mapChecks(checks).filter(check => !this.modelData?.modelChecks.some(({ id }) => check.id === id))
    },
    async saveModel () {
      const modelData = {
        ...this.modelData,
        modelChecks: this.modelData.modelChecks.map(({ id, order }) => ({ id, order }))
      }
      if (this.id) {
        await this.updateScoringModel({
          modelId: this.id,
          modelData
        })
        this.resetData()
      } else {
        await this.createScoringModel({
          modelData
        })
        this.$router.push({ name: 'EditScoringModel', params: { id: this.model.id } })
      }
    },
    async saveCheckParams ({
      check: {
        scoringCheckParameters,
        description,
        modelCheckId,
        id
      },
      done
    }) {
      await this.updateScoringModelChecks({
        checkParams: [
          {
            checkDescription: description,
            modelCheckId: modelCheckId ?? scoringCheckParameters[0].modelCheckId,
            params: scoringCheckParameters.map(({ description, fromValue, toValue, score, id }) => ({
              description,
              fromValue,
              toValue,
              score,
              id
            }))
          }
        ],
        checkId: id
      })
      done && done()
    },
    sort () {
      this.modelData.modelChecks = this.modelData.modelChecks.map((check, idx) => ({ ...check, order: idx }))
    }
  }
}
</script>


<style scoped lang="scss">
.card:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 0;
}

.check-list {
  max-height: 275px;
  overflow-y: auto;
}
</style>


<i18n>
en:
  modelConfigsTitle: "Model configuration"
  type:              "Model type"
  name:              "Model name"
  kyc:               "KYC check"
  description:       "Description"
  positiveScore:     "Positive score value up to"
  warningScore:      "Warning score value up to"
  negativeScore:     "Negative score value"
  scoreDescription:  "description"
  scoreStopValue:    "stop value"
  scoreChecksTitle:  "Scoring checks"
  country:           "Select country"
  addChecks:         "Add checks"
  fromValue:         "From value"
  toValue:           "To value"
  score:             "Score"
  selected:          "Selected"
  defaultValue:      "Default value"
  methodName:        "Method name"
  save:              "Save model"
et:
  modelConfigsTitle: "Model configuration"
  type:              "Model type"
  name:              "Model name"
  kyc:               "KYC check"
  description:       "Description"
  positiveScore:     "Positive score value up to"
  warningScore:      "Warning score value up to"
  negativeScore:     "Negative score value"
  scoreDescription:  "description"
  scoreStopValue:    "stop value"
  scoreChecksTitle:  "Scoring checks"
  country:           "Select country"
  addChecks:         "Add checks"
  fromValue:         "From value"
  toValue:           "To value"
  score:             "Score"
  selected:          "Selected"
  defaultValue:      "Default value"
  methodName:        "Method name"
  save:              "Save model"
ru:
  modelConfigsTitle: "Model configuration"
  type:              "Model type"
  name:              "Model name"
  kyc:               "KYC check"
  description:       "Description"
  positiveScore:     "Positive score value up to"
  warningScore:      "Warning score value up to"
  negativeScore:     "Negative score value"
  scoreDescription:  "description"
  scoreStopValue:    "stop value"
  scoreChecksTitle:  "Scoring checks"
  country:           "Select country"
  addChecks:         "Add checks"
  fromValue:         "From value"
  toValue:           "To value"
  score:             "Score"
  selected:          "Selected"
  defaultValue:      "Default value"
  methodName:        "Method name"
  save:              "Save model"
</i18n>

