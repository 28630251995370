<template lang="pug">
  form.scoring-model-check(@submit.prevent = 'submitCheck')
    .card-header
      .row
        .col-4
          fi-data-field.pb-0(
            :label     = '$t("methodName")'
            label-cols = 4
          ) {{ check.methodName }}
        .col-4
          fi-data-field.pb-0(
            :label = '$t("description")'
            label-cols=4
          )
            input.form-control-sm.form-control.animated.fadeIn(
              v-if    = 'editing'
              v-model = 'check.description'
            )
            template(v-else) {{ check.description }}
        .col.text-right
          button.btn.btn-link(
            v-if   = '!editing && editable'
            @click = 'toggleEditing'
          ) {{ $t('common:edit') }}
    .card-body
      fi-table(
        :fields = 'checkParamFields'
        :items  = 'check.scoringCheckParameters'
        fixed
      )
        template(#default = '{ items }')
          component(:is = 'scoringModelCheckParamComponent'
            v-for            = '(checkParameter, idx) in items'
            :key             = 'idx'
            :check-parameter = 'checkParameter'
            :editable        = 'check.editable'
            @add             = 'addCheckParameter(idx)'
            @delete          = 'deleteCheckParameter(idx)'
          )
      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiTable from '@/components/FiTable'
import ScoringModelCheckParamEdit from '@/views/admin/scoringModels/ScoringModelCheckParamEdit'
import ScoringModelCheckParamShow from '@/views/admin/scoringModels/ScoringModelCheckParamShow'

export default {
  name: 'scoring-model-check',

  components: {
    ScoringModelCheckParamEdit,
    FiDataField,
    FiTable
  },

  props: {
    check: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean
    }
  },

  data: () => ({
    editing: false
  }),

  computed: {
    checkParamFields () {
      return [
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'fromValue',
          label: this.$t('fromValue')
        },
        {
          key: 'toValue',
          label: this.$t('toValue')
        },
        {
          key: 'score',
          label: this.$t('score')
        },
        ...this.editing ? [{}] : []
      ]
    },
    saving () {
      return this.$vueLoading.isLoading(`scoring:model:check:${this.check.id}:save`)
    },
    scoringModelCheckParamComponent () {
      return this.editing ? ScoringModelCheckParamEdit : ScoringModelCheckParamShow
    }
  },

  methods: {
    toggleEditing () {
      this.$emit('update:editable', this.editing)
      this.editing = !this.editing
    },
    deleteCheckParameter (index) {
      this.check.scoringCheckParameters.splice(index, 1)
    },
    addCheckParameter (index) {
      this.check.scoringCheckParameters.splice(index + 1, 0, {
        description: null,
        fromValue: null,
        score: null,
        toValue: null
      })
    },
    submitCheck () {
      this.$emit('save', {
        check: this.check,
        done: () => this.toggleEditing()
      })
    }
  }
}
</script>
