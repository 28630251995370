<template lang="pug">
tr.scoring-model-list-item
  td {{ model.id }}
  td {{ model.modelName }}
  td {{ model.description }}
  td
    template(v-if = 'model.kycCheck') {{ $t('common:yes') }}
    template(v-else) {{ $t('common:no') }}
  td
    .form-row
      .col
        router-link.btn.btn-primary(:to = "{ name: 'EditScoringModel', params: { id: model.id } }") {{ $t('editScoringModel') }}
      .col
        router-link(:to = "{ name: 'NewScoringRequest', query: { modelId: model.id } }") {{ $t('newScoring') }}
</template>


<script>
export default {
  name: 'scoring-model-list-item',

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  newScoring:       "New scoring"
  editScoringModel: "Edit model"
et:
  newScoring:       "New scoring"
  editScoringModel: "Edit model"
ru:
  newScoring:       "New scoring"
  editScoringModel: "Edit model"
</i18n>
