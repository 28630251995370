<template lang="pug">
.card.scoring-request-result.animated.fadeIn
  .card-header {{ $t('resultTitle') }}
  .card-body
    fi-table(
      :fields = 'fields'
      :items  = 'requestChecks'
      fixed
    )
      template(#default = '{ items }')
        tr(v-for = '(value, index) in items')
          td {{ value.checkDescription || value.key }}
          td {{ value.result }}
          td {{ value.combinedScore }}
          td
            i.fas.fa-check-circle.fa-lg.text-success(v-if = 'value.checked')
          td
            a(v-if           = 'value.rawData || value.decisionData || value.exception'
              href           = '#'
              @click.prevent = 'openDetailsModal(value.key)') {{ $t('showAdditionalInfo') }}
          td
            button.btn.btn-primary.animated.fadeIn(
              v-if   = '(index === firstStoppedStep) && !isContinue'
              @click = 'continueRequest'
            ) {{ $t('continue') }}
    .alert(:class = 'resultState.class')
      p.mb-0: strong {{ $t('totalScore') }}: {{ requestResult.finalScore }}
    .alert(:class = 'resultState.class')
      p.mb-0: strong {{ $t('result') }}: {{ resultState.label }}
  scoring-request-result-modal(
    v-model    = 'showModal'
    :modalData = 'modalData'
  )
</template>


<script>
import { mapGetters } from 'vuex'
import ScoringRequestResultModal from './ScoringRequestResultModal.vue'
import FiTable from '@/components/FiTable'
import orderBy from 'lodash/orderBy'
import pickBy from 'lodash/pickBy'
import isObject from 'lodash/isObject'

const resultStates = {
  positive: 'POSITIVE',
  negative: 'NEGATIVE',
  warning: 'WARNING'
}

export default {
  name: 'scoring-request-result',

  components: { FiTable, ScoringRequestResultModal },

  props: {
    model: {
      type: Object,
      required: true
    },
    requestResult: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      showModal: false,
      modalData: {}
    }
  },

  computed: {
    ...mapGetters('scoring', ['requestResultContent']),
    resultStatus () {
      const { finalScore, attempt } = this.requestResult ?? {}
      if (finalScore < this.model.warningScoreValue || attempt === 3) {
        return resultStates.negative
      } else if (finalScore < this.model.positiveScoreValue) {
        return resultStates.warning
      } else {
        return resultStates.positive
      }
    },
    resultState () {
      const { negativeScoreHuman, positiveScoreHuman, warningScoreHuman } = this.model
      return {
        [resultStates.positive]: {
          class: 'alert-success',
          label: positiveScoreHuman
        },
        [resultStates.warning]: {
          class: 'alert-warning',
          label: warningScoreHuman
        },
        [resultStates.negative]: {
          class: 'alert-danger',
          label: negativeScoreHuman
        }
      }[this.resultStatus]
    },
    fields () {
      return [
        {
          label: this.$t('modelChecks'),
          key: 'modelChecks'
        },
        {
          label: this.$t('score'),
          key: 'score'
        },
        {
          label: this.$t('scoreCombined'),
          key: 'scoreCombined'
        },
        {
          label: this.$t('checked'),
          key: 'checked'
        },
        {
          label: this.$t('additionalInfo'),
          key: 'additionalInfo'
        },
        {}
      ]
    },
    requestChecks () {
      const checks = pickBy(this.requestResult, isObject)
      return orderBy(Object.entries(checks).map(([key, value]) => ({ ...value, key })), 'order', 'asc')
    },
    firstStoppedStep () {
      return this.requestChecks.findIndex((result) => typeof result === 'object' && !result.checked) - 1
    },
    isContinue () {
      return this.$vueLoading.isLoading('scoring:request:continue:save')
    }
  },

  methods: {
    openDetailsModal (key) {
      this.modalData = this.requestResultContent(key)
      this.showModal = true
    },
    continueRequest () {
      this.$emit('continue')
    }
  }
}
</script>


<style lang="scss">
.table td { vertical-align: middle; }
</style>


<i18n>
en:
  resultTitle:        "Result"
  modelChecks:        "Model checks"
  score:              "Check score"
  scoreCombined:      "Combined score"
  checked:            "Checked"
  additionalInfo:     "Additional info"
  showAdditionalInfo: "View details"
  totalScore:         "Total score"
  result:             "Result"
  continue:           "Score"
et:
  resultTitle:        "Result"
  modelChecks:        "Otsuse etapid"
  score:              "Check score"
  scoreCombined:      "Combined score"
  checked:            "Checked"
  additionalInfo:     "Lisainformatsioon"
  showAdditionalInfo: "Taidendav info"
  totalScore:         "Total score"
  result:             "Tulemus"
  continue:           "Score"
ru:
  resultTitle:        "Result"
  modelChecks:        "Model checks"
  score:              "Check score"
  scoreCombined:      "Combined score"
  checked:            "Checked"
  additionalInfo:     "Additional info"
  showAdditionalInfo: "View details"
  totalScore:         "Total score"
  result:             "Result"
  continue:           "Score"
</i18n>
