<template lang="pug">
.card.person-scoring-request
  .card-header {{ $t('requestTitle', { model: model.description, id: request.id }) }}
  .card-body
    .col-lg-8.col-xl-6
      fi-data-field(:label = '$t("date")') {{ $t('common:formatDatetime', { value: request.createdAt }) }}
      fi-data-field(:label = '$t("scoringModel")') {{ model.modelName }}
      fi-data-field(:label = '$t("name")') {{ customerName }}
      fi-data-field(:label = '$t("idCode")') {{ request.idCode }}
      fi-data-field(:label = '$t("idDocumentType")') {{ classifierById('idDocs', request.idDocId).human }}
      fi-data-field(:label = '$t("idDocumentNumber")') {{ request.idDocNumber }}
      fi-data-field(:label = '$t("age")') {{ request.age }}
      fi-data-field(:label = '$t("county")') {{ classifierById('counties', request.countyId).human }}
      fi-data-field(:label = '$t("employmentLength")') {{ request.employmentLength }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'
import { customerName } from '@/helpers'

export default {
  name: 'person-scoring-request',

  components: { FiDataField },

  props: {
    request: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    customerName () {
      return customerName(this.request)
    }
  }
}
</script>


<i18n>
en:
  requestTitle:     "{{model}} request #{{id}}"
  date:             "Date"
  scoringModel:     "Scoring model"
  name:             "Name"
  idCode:           "ID code"
  idDocumentType:   "ID document type"
  idDocumentNumber: "ID document number"
  age:              "Age"
  county:           "County"
  employmentLength: "Employment length"
et:
  requestTitle:     "{{model}} request #{{id}}"
  date:             "Date"
  scoringModel:     "Otsingumudel"
  name:             "Nimi"
  idCode:           "Isikukood"
  idDocumentType:   "Konto avaja documendi tüüp"
  idDocumentNumber: "Dokumendi number"
  age:              "Vanus"
  county:           "Taotleja elukoha maakond"
  employmentLength: "Töötatud aegu kuus"
ru:
  requestTitle:     "{{model}} запрос #{{id}}"
  date:             "Дата"
  scoringModel:     "Скоринговая модель"
  name:             "Имя"
  idCode:           "ID код"
  idDocumentType:   "Тип документа"
  idDocumentNumber: "Номер документа"
  age:              "Возраст"
  county:           "Округ"
  employmentLength: "Стаж"
</i18n>
