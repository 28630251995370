<template lang="pug">
.scoring-model
  .text-center(v-if = '$vueLoading.isLoading("scoring:model:config:fetch")'): i.fa.fa-spinner.fa-pulse.fa-lg
  .animated.fadeIn(v-else)
    .card
      .card-header {{ $t('modelConfigsTitle') }} {{ '#' + model.id }}
      .card-body
        fi-data-field(
          :label      = '$t("model")'
          :label-cols = '3'
        ) {{ model.modelName }}
        fi-data-field(
          :label      = '$t("description")'
          :label-cols = '3'
        ) {{ model.description }}
        fi-data-field(
          :label      = '$t("positiveScore")'
          :label-cols = '3'
        ) {{ model.positiveScoreValue }}
        fi-data-field(
          :label      = '$t("negativeScore")'
          :label-cols = '3'
        ) {{ model.negativeScoreValue }}
        fi-data-field(
          :label      = '$t("positiveDescription")'
          :label-cols = '3'
        ) {{ model.positiveScoreHuman }}
        fi-data-field(
          :label      = '$t("negativeDescription")'
          :label-cols = '3'
        ) {{ model.negativeScoreHuman }}
        fi-data-field(
          :label      = '$t("recheckDescription")'
          :label-cols = '3'
        ) {{ model.recheckScoreHuman }}
    .card
      .card-header {{ $t('scoreChecksTitle') }}
      .card-body
        .card(v-for = 'scoringCheck in scoringChecksFiltered')
          .card-header {{ scoringCheck.description }}
          .card-body
            fi-table(
              :fields = 'fields'
              :items  = 'scoringCheck.scoringCheckParameters'
              responsive
              fixed
            )
</template>


<script>
import { mapActions, mapState } from 'vuex'
import FiDataField from '@/components/FiDataField'
import FiTable from '@/components/FiTable'

export default {
  name: 'scoring-model',

  components: { FiTable, FiDataField },

  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('scoring', ['model']),
    scoringChecksFiltered () {
      return this.model.scoringChecks.filter(({ scoringCheckParameters }) => scoringCheckParameters.length)
    },
    fields () {
      return [
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'fromValue',
          label: this.$t('fromValue')
        },
        {
          key: 'toValue',
          label: this.$t('toValue')
        },
        {
          key: 'score',
          label: this.$t('score')
        }
      ]
    }
  },

  created () {
    this.loadModelConfigs({ id: this.id })
  },

  methods: mapActions('scoring', ['loadModelConfigs'])
}
</script>


<style scoped lang="scss">
.card:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 0;
}
</style>


<i18n>
en:
  modelConfigsTitle:   "Model configuration"
  model:               "Model"
  description:         "Description"
  positiveScore:       "Positive score value"
  negativeScore:       "Negative score value"
  positiveDescription: "Positive score description"
  negativeDescription: "Negative score description"
  recheckDescription:  "Rechecking score description"
  scoreChecksTitle:    "Scoring checks"
  fromValue:           "From value"
  toValue:             "To value"
  score:               "Score"
et:
  modelConfigsTitle:   "Model configuration"
  model:               "Model"
  description:         "Description"
  positiveScore:       "Positive score value"
  negativeScore:       "Negative score value"
  positiveDescription: "Positive score description"
  negativeDescription: "Negative score description"
  recheckDescription:  "Rechecking score description"
  scoreChecksTitle:    "Scoring checks"
  fromValue:           "From value"
  toValue:             "To value"
  score:               "Score"
ru:
  modelConfigsTitle:   "Model configuration"
  model:               "Model"
  description:         "Description"
  positiveScore:       "Positive score value"
  negativeScore:       "Negative score value"
  positiveDescription: "Positive score description"
  negativeDescription: "Negative score description"
  recheckDescription:  "Rechecking score description"
  scoreChecksTitle:    "Scoring checks"
  fromValue:           "From value"
  toValue:             "To value"
  score:               "Score"
</i18n>

