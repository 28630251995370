<template lang="pug">
.finance-scoring-request-form.card
  .card-header {{ model.description }}
  .card-body
    form.form-horizontal.col-lg-9.col-xl-6(@submit.prevent = 'onSubmit')
      fi-form-field(:label = '$t("idCode")')
        input.form-control.form-control-sm(
          type           = 'text'
          v-model.number = 'requestData.idCode'
        )
      fi-form-field(:label = '$t("firstName")')
        input.form-control.form-control-sm(
          type         = 'text'
          v-model.trim = 'requestData.firstName'
        )
      fi-form-field(:label = '$t("lastName")')
        input.form-control.form-control-sm(
          type         = 'text'
          v-model.trim = 'requestData.lastName'
        )
      template(v-if = '!isPreScoringModel')
        fi-form-field(:label = '$t("loanAmount")')
          input.form-control.form-control-sm(
            type           = 'text'
            v-model.number = 'requestData.loanAmount'
          )
        fi-form-field(:label = '$t("loanLength")')
          input.form-control.form-control-sm(
            type           = 'text'
            v-model.number = 'requestData.loanLength'
          )
        fi-form-field(:label = '$t("ageAfterLoanPeriod")')
          input.form-control.form-control-sm(
            type           = 'text'
            v-model.number = 'requestData.ageAfterLoanPeriod'
          )
      fi-form-field(:label = '$t("obligations")')
        input.form-control.form-control-sm(
          type           = 'text'
          v-model.number = 'requestData.obligations'
        )
      fi-form-field(:label = '$t("netIncome")')
        input.form-control.form-control-sm(
          type           = 'text'
          v-model.number = 'requestData.netIncome'
        )
      template(v-if = '!isPreScoringModel')
        fi-form-field(:label = '$t("idDocumentType")')
          fi-select(
            v-model.number = 'requestData.idDocId'
            :options       = 'optionsFromClassifier("idDocs")'
            sm
          )
        fi-form-field(:label = '$t("idDocumentNumber")')
          input.form-control.form-control-sm(
            type           = 'text'
            v-model.number = 'requestData.idDocNumber'
          )

      button.btn.btn-primary(
        type      = 'submit'
        :disabled = 'saving'
      ) {{ $t('execute') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'

export default {
  name: 'finance-scoring-request-form',

  components: {
    FiSelect,
    FiFormField
  },

  props: {
    model: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      requestData: {
        idCode: null,
        firstName: null,
        lastName: null,
        loanAmount: null,
        loanLength: null,
        ageAfterLoanPeriod: null,
        obligations: null,
        netIncome: null,
        idDocId: '',
        idDocNumber: null
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('scoring', ['currentRequest']),
    isPreScoringModel () {
      return this.model.modelName === 'preScoring'
    },
    saving () {
      return this.$vueLoading.isLoading('scoring:request:save')
    }
  },

  created () {
    this.resetData()
  },

  methods: {
    ...mapActions('scoring', ['createRequest']),
    resetData () {
      if (!this.customer) return
      this.requestData = {
        ...this.requestData,
        ...pick(this.customer, Object.keys(this.requestData))
      }
    },
    async onSubmit () {
      await this.createRequest({ modelId: this.model.id, requestData: this.$data.requestData, type: this.model.modelType.toLowerCase() })
      this.$router.push({ name: 'ScoringRequest', params: { id: this.currentRequest.id, type: this.model.modelType.toLowerCase() } })
    }
  }
}
</script>


<i18n>
en:
  requestTitle:       "{{model}} request #{{id}}"
  firstName:          "First name"
  lastName:           "Last name"
  idCode:             "ID code"
  idDocumentType:     "ID document type"
  idDocumentNumber:   "ID document number"
  loanAmount:         "Loan amount"
  loanLength:         "Loan length"
  ageAfterLoanPeriod: "Age after loan period"
  obligations:        "Obligations"
  netIncome:          "Net income"
  execute:            "Execute"
et:
  requestTitle:       "{{model}} request #{{id}}"
  firstName:          "Eesnimi"
  lastName:           "Perekonnanimi"
  idCode:             "Isikukood"
  idDocumentType:     "Konto avaja documendi tüüp"
  idDocumentNumber:   "Dokumendi number"
  loanAmount:         "Taotletav finantseeringu summa"
  loanLength:         "Perioodi pikkus kuudes"
  ageAfterLoanPeriod: "Taotleja vanus perioodi löpus"
  obligations:        "Taotleja olemasolevad finantskohustused"
  netIncome:          "Taotleja netosissetuleku summa"
  execute:            "Execute"
ru:
  requestTitle:       "{{model}} request #{{id}}"
  firstName:          "Имя"
  lastName:           "Фамилия"
  idCode:             "ID код"
  idDocumentType:     "Тип ID документа"
  idDocumentNumber:   "Номер ID документа"
  loanAmount:         "Сумма кредита"
  loanLength:         "Срок кредита"
  ageAfterLoanPeriod: "Возраст по окончании кредита"
  obligations:        "Финансовые обязательства"
  netIncome:          "Доход"
  execute:            "Выполнить"
</i18n>
