<template lang="pug">
.animated.fadeIn.scoring-model-list
  .card
    .card-header {{ $t('menu:scoringModels') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newLink') }}
    .card-body
      .table-responsive
        table.table.table-hover
          thead: tr
            th ID
            th {{ $t('model') }}
            th {{ $t('description') }}
            th {{ $t('personal') }}
            th
          tbody
            tr(v-if = 'Object.keys(scoringModels).length === 0'): td.text-center(colspan = 5) {{ $t('notFound') }}
            template(v-else)
              scoring-model-list-item(
                v-for  = 'model in sortClassifiers'
                :key   = 'model.id'
                :model = 'model'
              )
</template>


<script>
import { mapActions, mapState } from 'vuex'
import ScoringModelListItem from './ScoringModelListItem'

export default {
  name: 'scoring-model-list',

  components: { ScoringModelListItem },

  i18nOptions: {},

  computed: {
    ...mapState('scoring', ['scoringModels']),
    sortClassifiers () {
      const list = Object.values(JSON.parse(JSON.stringify(this.scoringModels)))
      const sortList = list.sort(function (a, b) {
        const nameA = a.description
        const nameB = b.description
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  created () {
    this.loadScoringModels()
  },

  methods: mapActions('scoring', ['loadScoringModels'])
}
</script>


<i18n>
en:
  model:       "Model"
  newLink:     "+ New scoring model"
  description: "Description"
  personal:    "Customer scoring"
  notFound:    "No models found"
et:
  model:       "Model"
  newLink:     "+ New scoring model"
  description: "Description"
  personal:    "Customer scoring"
  notFound:    "No models found"
ru:
  model:       "Model"
  newLink:     "+ New scoring model"
  description: "Description"
  personal:    "Customer scoring"
  notFound:    "No models found"
</i18n>
