  <template lang="pug">
fi-modal.scoring-request-result-modal(ref     = 'modal'
                                      v-model = 'toggleModal'
                                      :title  = 'modalData.checkDescription || modalData.scoreCheckName'
                                      size    = 'lg'
                                      lazy)
  .card.border-0
    .card-body
      fi-data-field(:label     = '$t("scoringResult")'
                    label-cols = 3) {{ modalData.result }}
      fi-data-field(v-if       = 'modalData.exception'
                    :label     = '$t("exception")'
                    label-cols = 3)
        .text-danger {{ modalData.exception }}
      fi-data-field(:label     = '$t("decisionData")'
                    label-cols = 3)
        pre.row.col-form-label.font-italic(v-if = 'hasDecision') {{ formattedDecision }}
        span(v-else
             v-html = 'sanitizedDecisionData')
      fi-data-field(:label     = '$t("externalResponse")'
                    label-cols = 3)
        table(v-if = 'contentHasJson && paymentDefaults')
          tbody(v-for = 'paymentDefault in paymentDefaults')
              tr
                th {{ $t('owner') }}
                td {{ formatOwner(paymentDefault.owner) }}
              tr
                th {{ $t('ownerActivity') }}
                td {{ formatActivity(paymentDefault.owner.activity) }}
              tr
                th {{ $t('debtRange') }}
                td {{ paymentDefault.debtRange }}
              tr
                th {{ $t('maxDebtRange') }}
                td {{ paymentDefault.maxDebtRange }}
              tr
                th {{ $t('beginDate') }}
                td {{ $t('common:formatDate', { value: paymentDefault.beginDate }) }}
              tr
                th {{ $t('endDate') }}
                td {{ $t('common:formatDate', { value: paymentDefault.endDate }) }}
        table(v-if = 'contentHasJson && proceedsFromTaxOffice && proceedsFromTaxOffice.length')
          thead: tr
            th {{ $t('operationDate') }}
            th {{ $t('operationAmount') }}
          tbody
            template(v-for = 'operation in proceedsFromTaxOffice')
              tr
                td {{ $t('common:formatDate', { value: operation.operationDate }) }}
                td {{ formatOperationAmount(operation) }}
        .row.col-form-label.font-italic(v-else-if = 'content')
          .col-sm-12(v-if   = '!modalData.content.html')
            pre.pre-scrollable {{ content }}
          .col-sm-12
            button.btn.btn-primary(@click='downloadExternalResponse') {{ $t('common:download') }}

  template(#modal-footer)
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:close") }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiModal from '@/components/FiModal'
import sanitizeHtml from 'sanitize-html'
import numeral from 'numeral'
import { mapActions } from 'vuex'

export default {
  name: 'scoring-request-result-modal',

  components: {
    FiDataField,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    toggleModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    sanitizedDecisionData () {
      return sanitizeHtml(this.modalData.decisionData || '')
    },
    content () {
      const { content } = this.modalData.content ?? {}
      return content
    },
    contentHasJson () {
      return this.modalData.content?.json
    },
    hasDecision () {
      return !!this.modalData.decision
    },
    paymentDefaults () {
      return this.content?.paymentDefaults ?? []
    },
    proceedsFromTaxOffice () {
      return this.content?.proceeds ?? []
    },
    formattedDecision () {
      const checkName = this.modalData.scoreCheckName || ''
      if (checkName === 'checkPensionAccount') {
        const { result, computedNetIncome, incomeDifference } = this.modalData.decision || {}
        let label
        switch (result) {
          case 'NO_ACCOUNT':
            return this.$t('pensionNA')
          case 'TOO_LITTLE_PROCEEDS':
            return this.$t('pensionNotEnough')
          case 'OK':
            label = 'pensionOK'
            break
          case 'TOO_LARGE_INCOME_DIFFERENCE':
            label = 'pensionNOK'
        }
        return this.$t(label, { computed: computedNetIncome, difference: incomeDifference })
      } else if (checkName === 'checkPensionIncome') {
        const { result, computedNetIncome } = this.modalData.decision || {}
        switch (result) {
          case 'NO_ACCOUNT':
            return this.$t('pensionNA')
          case 'TOO_LITTLE_PROCEEDS':
            return this.$t('pensionNotEnough')
          case 'OK':
          case 'NOK':
            return this.$t('netIncome', { computed: computedNetIncome })
        }
      } else if (checkName === 'checkLoanLimit') {
        const { result, loanLimit } = this.modalData.decision || {}
        switch (result) {
          case 'CANNOT_BE_COMPUTED':
            return this.$t('loanLimitNA')
          case 'OK':
          case 'NOK':
            return this.$t('loanLimit', { computed: loanLimit })
        }
      }
      return this.modalData.decisionData
    }
  },

  methods: {
    ...mapActions('scoring', ['loadExternalResponseReport']),
    downloadExternalResponse () {
      if (this.contentHasJson) {
        this.loadExternalResponseReport({
          data: new Blob([JSON.stringify(this.modalData.content.content, null, 2)], { type: 'application/json' }),
          filename: 'external-response.json'
        })
      } else {
        this.loadExternalResponseReport({
          data: new Blob([this.modalData.content.content], { type: 'text/html' }),
          filename: 'external-response.html'
        })
      }
    },
    formatOwner (owner) {
      let result = ''
      if (owner) {
        if (owner.name) {
          result = owner.name
        }
        if (owner.registryCode) {
          if (result) {
            result += ' '
          }
          result += '(' + owner.registryCode + ')'
        }
      }
      return result
    },

    formatActivity (activity) {
      let result = activity.code
      if (activity.value) {
        result += ' - ' + activity.value
      }
      return result
    },

    formatMonetaryAmount (amount) {
      return numeral(amount).format('0,0.00')
    },

    formatOperationAmount (operation) {
      let result = ''
      if (operation) {
        const sum = operation.sum
        if (sum || sum === 0) {
          result += this.formatMonetaryAmount(sum)
        }
        const currency = operation.currency
        if (currency) {
          result += ' ' + currency
        }
      }
      return result
    }
  }
}
</script>


<i18n>
en:
  scoringResult:    "Scoring result"
  decisionData:     "Decision data"
  externalResponse: "External response"
  exception:        "Exception"
  owner:            "Debt owner"
  ownerActivity:    "Debt owner activity"
  debtRange:        "Debt range"
  maxDebtRange:     "Maximum debt range"
  beginDate:        "Began at"
  endDate:          "Ended at"
  operationDate:    "Date"
  operationAmount:  "Amount"
  pensionOK:        "Computed net income is {{computed}} EUR (difference from declared is {{difference}} EUR)"
  pensionNOK:       "Computed net income is {{computed}} EUR (difference from declared is {{difference}} EUR, which is too large)"
  pensionNA:        "No pension account present"
  pensionNotEnough: "Not enough recent proceeds from tax office"
  netIncome:        "Net income is {{computed}} EUR"
  loanLimit:        "Limit is {{computed}} EUR"
  loanLimitNA:      "Limit could not be calculated"
et:
  scoringResult:    "Scoring result"
  decisionData:     "Decision data"
  externalResponse: "External response"
  exception:        "Exception"
  owner:            "Debt owner"
  ownerActivity:    "Debt owner activity"
  debtRange:        "Debt range"
  maxDebtRange:     "Maximum debt range"
  beginDate:        "Began at"
  endDate:          "Ended at"
  operationDate:    "Date"
  operationAmount:  "Amount"
  pensionOK:        "Computed net income is {{computed}} EUR (difference from declared is {{difference}} EUR)"
  pensionNOK:       "Computed net income is {{computed}} EUR (difference from declared is {{difference}} EUR which is too large)"
  pensionNA:        "No pension account present"
  pensionNotEnough: "Not enough recent proceeds from tax office"
  netIncome:        "Net income is {{computed}} EUR"
  loanLimit:        "Limit is {{computed}} EUR"
  loanLimitNA:      "Limit could not be calculated"
ru:
  scoringResult:    "Scoring result"
  decisionData:     "Decision data"
  externalResponse: "External response"
  exception:        "Exception"
  owner:            "Debt owner"
  ownerActivity:    "Debt owner activity"
  debtRange:        "Debt range"
  maxDebtRange:     "Maximum debt range"
  beginDate:        "Began at"
  endDate:          "Ended at"
  operationDate:    "Date"
  operationAmount:  "Amount"
  pensionOK:        "Computed net income is {{computed}} EUR (difference from declared is {{difference}} EUR)"
  pensionNOK:       "Computed net income is {{computed}} EUR (difference from declared is {{difference}} EUR which is too large)"
  pensionNA:        "No pension account present"
  pensionNotEnough: "Not enough recent proceeds from tax office"
  netIncome:        "Net income is {{computed}} EUR"
  loanLimit:        "Limit is {{computed}} EUR"
  loanLimitNA:      "Limit could not be calculated"
</i18n>
