import { render, staticRenderFns } from "./ScoringModelCheckParamShow.vue?vue&type=template&id=46e85068&lang=pug&functional=true&"
import script from "./ScoringModelCheckParamShow.vue?vue&type=script&lang=js&"
export * from "./ScoringModelCheckParamShow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports