<template lang="pug">
.scoring-request
  .text-center(v-if = '$vueLoading.isLoading("scoring:request:fetch")'): i.fa.fa-spinner.fa-pulse.fa-lg
  template(v-else)
    person-scoring-request(v-if     = 'requestModel.kycCheck'
                           :request = 'currentRequest'
                           :model   = 'requestModel')
    finance-scoring-request(v-else
                            :request = 'currentRequest'
                            :model   = 'requestModel')

    scoring-request-result.animated.fadeIn(
      :model         = 'requestModel'
      :requestResult = 'requestResult'
      @continue      = 'handleContinueRequest'
    )
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PersonScoringRequest from './PersonScoringRequest'
import FinanceScoringRequest from './FinanceScoringRequest'
import ScoringRequestResult from './ScoringRequestResult'

export default {
  name: 'scoring-request',

  components: { PersonScoringRequest, FinanceScoringRequest, ScoringRequestResult },

  props: {
    id: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('scoring', ['currentRequest']),
    ...mapGetters('scoring', ['scoringModelById']),
    requestModel () {
      return this.scoringModelById(this.currentRequest.scoringModelId)
    },
    requestResult () {
      return this.currentRequest?.scoringResponseModel ?? {}
    }
  },

  created () {
    this.loadRequest({ requestId: this.id, type: this.type })
    this.loadScoringModels()
  },

  methods: {
    ...mapActions('scoring', ['loadRequest', 'continueRequest', 'loadScoringModels']),
    handleContinueRequest () {
      this.continueRequest({
        type: this.type,
        requestId: this.currentRequest.id,
        modelId: this.currentRequest.scoringModelId
      })
    }
  }
}
</script>
