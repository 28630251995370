<template lang="pug">
.animated.fadeIn.new-scoring-request
  component(
    :is       = 'scoringForm'
    :model    = 'scoringModelById(modelId)'
    :customer = 'customer'
  )
</template>


<script>
import { mapGetters } from 'vuex'
import PersonScoringRequestForm from './PersonScoringRequestForm'
import FinanceScoringRequestForm from './FinanceScoringRequestForm'

export default {
  name: 'new-scoring-request',

  components: { PersonScoringRequestForm, FinanceScoringRequestForm },

  props: {
    modelId: {
      type: [Number, String],
      required: true
    },
    customer: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters('scoring', ['scoringModelById']),
    scoringForm () {
      if (this.scoringModelById(this.modelId).kycCheck) {
        return 'PersonScoringRequestForm'
      }
      return 'FinanceScoringRequestForm'
    }
  }
}
</script>
